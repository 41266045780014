import { Composer } from 'vue-i18n'
import { loadMulpay } from '@smbc-gp/mptoken-js'
import type {
  MultiPayment,
  MultiPaymentElements,
  MultiPaymentCardNumberElementOptions,
  MultiPaymentCardExpiryElement,
  MultiPaymentCardCvcElement,
  MultiPaymentCardNumberElement,
  TokenResponse,
  TokenObj,
} from '@smbc-gp/mptoken-js'
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import {
  PostPaymentGmoOrdersRequest,
  PostPaymentGmo3dSecureRequest,
} from '@/composables/repository/useGmoPaymentRepository'

export type GmoPaymentError = {
  cardNumber: string
  expiry: string
  cvc: string
}

const BASE_STYLE: MultiPaymentCardNumberElementOptions['style'] = {
  base: {
    fontFamily:
      'Helvetica Neue,Arial,Hiragino Kaku Gothic ProN,Hiragino Sans,Meiryo,sans-serif',
    '::placeholder': {
      color: '#757575',
    },
    lineHeight: '1.2em',
  },
  focus: {},
  invalid: {
    color: '#9e2146',
  },
}

const SESSION_STORAGE_KEY = 'gmo-payment-token-locale'

/**
 * @see https://static.smbc-gp.co.jp/doc/card-token/#tag/intro
 * @description 以下のコードはmiddlewareでこのcomposablesを使うと警告が出るので各関数で定義している
 * 「const repositoryFactory = useRepositoryFactory()」
 */
export const useGmoPayment = () => {
  // composables
  const runtimeConfig = useRuntimeConfig()
  const i18n = useNuxtApp().$i18n as Composer

  // refs
  const mulpay = ref<MultiPayment | null>(null)
  const elements = ref<MultiPaymentElements>()
  const mulPayFormElement = ref<{
    cardNumberInputElement?: MultiPaymentCardNumberElement
    expiryInputElement?: MultiPaymentCardExpiryElement
    cvcInputElement?: MultiPaymentCardCvcElement
  }>({})
  const elementComplete = ref({
    cardNumber: false,
    expiry: false,
    cvc: false,
  })
  const elementError = ref<GmoPaymentError>({
    cardNumber: '',
    expiry: '',
    cvc: '',
  })
  const uuid = ref<string>('')

  // computed
  const paymentValid = computed(() => {
    return (
      elementComplete.value.cardNumber === true &&
      elementComplete.value.expiry === true &&
      elementComplete.value.cvc === true
    )
  })

  const errorHelpMessage = computed(() =>
    // NOTE: globalのlocalesがjsonファイルでHTMLタグの埋め込みができないため、ハードコーディング
    i18n.locale.value === 'ja'
      ? 'エラーが発生しました。解決しない場合は<a href="/help#クレジットカード決済時に「エラーが発生しました。決済に失敗しました。」と表示されました" target="_blank">こちら</a>。'
      : 'An error has occurred. Please see <a href="/en/help#While%20processing%20Credit%20Card%20payment,%20I%20was%20notified%20that%20An%20error%20has%20occurred.%20Payment%20failed." target="_blank">here</a> if the issue persists.'
  )

  const init = async () => {
    if (import.meta.server) throw new Error('server side not supported')
    mulpay.value = await loadMulpay(
      runtimeConfig.public.NUXT_ENV_GMO_PAYMENT_API_KEY,
      runtimeConfig.public.NUXT_ENV_GMO_PAYMENT_PUBLIC_KEY,
      {}, // NOTE: google / apple の pay を使う場合 merchantIds を指定。使わないので空
      true,
      runtimeConfig.public.NUXT_ENV_OUTPUT_ENV === 'production'
    )
    if (!mulpay.value) throw new Error('faild load mulpay')
    elements.value = mulpay.value.createElements()

    const cardNumberInputElement = elements.value.create('cardNumber', {
      style: BASE_STYLE,
      placeholder: i18n.t('credit.placeholder.card-number'),
    })
    const expiryInputElement = elements.value.create('cardExpiry', {
      style: BASE_STYLE,
      placeholder: i18n.t('credit.placeholder.card-expiry'),
    })
    const cvcInputElement = elements.value.create('cardCvc', {
      style: BASE_STYLE,
      placeholder: i18n.t('credit.placeholder.card-cvc'),
    })

    // カード情報入力フォームのラッパー要素を取得する
    const cardNumberWrapperElement = document.getElementById('cardNumber')
    const cardExpiryWrapperElement = document.getElementById('cardExpiry')
    const cardCvcWrapperElement = document.getElementById('cardCvc')

    // カード情報入力フォームをラッパー要素にマウントする
    if (
      !cardNumberWrapperElement ||
      !cardExpiryWrapperElement ||
      !cardCvcWrapperElement
    ) {
      throw new Error(
        'cardNumberWrapperElement or cardExpiryWrapperElement or cardCvcWrapperElement is null'
      )
    }
    cardNumberInputElement.mount(cardNumberWrapperElement)
    expiryInputElement.mount(cardExpiryWrapperElement)
    cvcInputElement.mount(cardCvcWrapperElement)

    cardNumberInputElement.on('change', (e) => {
      elementComplete.value.cardNumber = e.complete
      elementError.value.cardNumber =
        !e.complete && !!e.error ? i18n.t('credit.error.card-number') : ''
    })
    expiryInputElement.on('change', (e) => {
      elementComplete.value.expiry = e.complete
      elementError.value.expiry =
        !e.complete && !!e.error ? i18n.t('credit.error.card-expiry') : ''
    })

    cvcInputElement.on('change', (e) => {
      elementComplete.value.cvc = e.complete
      elementError.value.cvc =
        !e.complete && !!e.error ? i18n.t('credit.error.card-cvc') : ''
    })

    // カード情報入力フォームのラッパー要素を配列に格納する
    mulPayFormElement.value.cardNumberInputElement = cardNumberInputElement
    mulPayFormElement.value.expiryInputElement = expiryInputElement
    mulPayFormElement.value.cvcInputElement = cvcInputElement
  }

  const postOrders = async (params: PostPaymentGmoOrdersRequest) => {
    const repositoryFactory = useRepositoryFactory()
    const gmoRepository = repositoryFactory.get('gmo')
    const result = await gmoRepository.post.postPaymentGmoOrders(params)
    uuid.value = result.uuid
    return uuid.value
  }

  const getGmoPaymentToken = async () => {
    if (!mulpay.value || !mulPayFormElement.value.cardNumberInputElement)
      throw new Error('mulpay or cardNumberInputElement is null')

    const tokenResponse = await mulpay.value.getTokenThroughIframe(
      mulPayFormElement.value.cardNumberInputElement,
      undefined,
      {
        tokenNumber: '2',
      }
    )

    if (
      !isTokenObject(tokenResponse) ||
      tokenResponse.result !== 'success' ||
      !tokenResponse.tokenList[0]
    ) {
      throw new Error('gmo payment token error')
    }
    return tokenResponse
  }

  const executeGmoPayment3DSecure = async (token: string) => {
    const repositoryFactory = useRepositoryFactory()
    const gmoRepository = repositoryFactory.get('gmo')
    const result = await gmoRepository.post.postPaymentGmo3dSecurePre({
      uuid: uuid.value,
      token,
    })

    // NOTE: 英語表記でリダイレクトしたときに日本語に戻すため、セッションストレージにlocaleを保存
    setLocalStorageValue(SESSION_STORAGE_KEY, i18n.locale.value)
    location.href = result.redirectUrl
  }

  const isTokenObject = (object: TokenResponse): object is TokenObj => {
    return (object as TokenObj).maskedCardNumber !== undefined
  }

  const submit = (params: PostPaymentGmo3dSecureRequest) => {
    const repositoryFactory = useRepositoryFactory()
    const gmoRepository = repositoryFactory.get('gmo')
    return gmoRepository.post.postPaymentGmo3dSecure(params)
  }

  const setCardNumberError = (message = errorHelpMessage.value) => {
    elementError.value.cardNumber = message
  }

  /**
   * 3DセキュアからリダイレクトしてMyVketに戻った時に言語を切り替えるために使う
   * @returns string
   */
  const getRedirectLocaleSession = () => {
    const locale = getLocalStorageValue(SESSION_STORAGE_KEY)
    return locale
  }
  const clearRedirectLocaleSession = () => {
    removeLocalStorageValue(SESSION_STORAGE_KEY)
  }

  return {
    elementError: readonly(elementError),
    paymentValid,
    init,
    postOrders,
    getGmoPaymentToken,
    executeGmoPayment3DSecure,
    submit,
    setCardNumberError,
    getRedirectLocaleSession,
    clearRedirectLocaleSession,
  }
}

export type GmoPaymentComposable = ReturnType<typeof useGmoPayment>
export const gmoPaymentInjectionKey: InjectionKey<GmoPaymentComposable> =
  Symbol('gmoPayment')
