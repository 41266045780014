import { z } from 'zod'
import { myVketSelectItem } from './ui'
import { TARGET_AGE } from '@/utils/constants'
import { profileData, profileDetails } from '@/models/profiles'

export const LIST_TYPE = {
  POPULAR: 'popular',
  OFFICIAL: 'official',
  BUNDLE: 'bundle',
  AVATAR: 'avatar',
  HOUSING: 'housing',
  PICKUP: 'pickup',
  VKETBOOTHMARCHE: 'vketBoothMarche',
  AVATARPART: 'avatarPart',
  OTHER: 'other',
  CATEGORY: 'category',
} as const

export const listType = z.enum([
  LIST_TYPE.POPULAR,
  LIST_TYPE.OFFICIAL,
  LIST_TYPE.BUNDLE,
  LIST_TYPE.AVATAR,
  LIST_TYPE.HOUSING,
  LIST_TYPE.PICKUP,
  LIST_TYPE.VKETBOOTHMARCHE,
  LIST_TYPE.AVATARPART,
  LIST_TYPE.OTHER,
  LIST_TYPE.CATEGORY,
])

export const CATEGORY = {
  AVATAR: 'avatar',
  HOUSING_FURNITURE: 'housing_furniture',
  AVATAR_PART: 'avatar_part',
} as const
export const categoryType = z.enum([
  CATEGORY.AVATAR,
  CATEGORY.HOUSING_FURNITURE,
  CATEGORY.AVATAR_PART,
])
export type CategoryType = z.infer<typeof categoryType>

export const categoryItem = myVketSelectItem.extend({
  value: categoryType,
})
export type CategoryItem = z.infer<typeof categoryItem>

export const SUB_CATEGORY = {
  NO_SUB_CATEGORY: 'no_sub_category',
  FLOOR: 'floor',
  CEILING: 'ceiling',
  WALL: 'wall',
  PRESET: 'preset',
} as const
export const subCategoryType = z.enum([
  SUB_CATEGORY.NO_SUB_CATEGORY,
  SUB_CATEGORY.FLOOR,
  SUB_CATEGORY.CEILING,
  SUB_CATEGORY.WALL,
  SUB_CATEGORY.PRESET,
])
export type SubCategoryType = z.infer<typeof subCategoryType>

export const ACCESSIBLE_STATUS_TYPE = {
  PRIVATE: 'private',
  LIMITED: 'limited',
  PUBLISHED: 'published',
} as const
export const accessibleStatusType = z.enum([
  ACCESSIBLE_STATUS_TYPE.PRIVATE, // 非公開
  ACCESSIBLE_STATUS_TYPE.LIMITED, // 限定公開
  ACCESSIBLE_STATUS_TYPE.PUBLISHED, // 公開
])

export const subThumbnail = z.object({
  id: z.number(),
  itemId: z.number(),
  thumbnail: z.object({
    url: z.string().nullable(),
    medium: z
      .object({
        url: z.string().nullable(),
      })
      .optional(),
  }),
})
export type SubThumbnail = z.infer<typeof subCategoryType>

export const SORT_TYPE = {
  NEWER: 'newer',
  OLDER: 'older',
  POPULAR: 'popular',
  PURCHASES: 'purchases',
  LIKES: 'likes',
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
} as const

export const sortType = z.enum([
  SORT_TYPE.NEWER,
  SORT_TYPE.OLDER,
  SORT_TYPE.POPULAR,
  SORT_TYPE.PURCHASES,
  SORT_TYPE.LIKES,
  SORT_TYPE.PRICE_ASC,
  SORT_TYPE.PRICE_DESC,
])
export type SortType = z.infer<typeof sortType>

export const cloudStoreSortData = z.object({
  value: sortType,
  text: z.string(),
})

export type CloudStoreSortData = z.infer<typeof cloudStoreSortData>

export const OBJECT_TYPE = {
  OBJECT: 'object',
  ACTIVITY: 'activity',
} as const
export const objectType = z.enum([OBJECT_TYPE.OBJECT, OBJECT_TYPE.ACTIVITY])
export type ObjectType = z.infer<typeof objectType>

export const user = z.object({
  id: z.number(),
  vketId: z.string(),
  nameJa: z.string().nullable(),
  nameEn: z.string().nullable(),
  email: z.string().nullable(),
  isOfficialAccount: z.boolean().optional(),
  createdAt: z.union([z.instanceof(Date), z.string()]).optional(),
  updatedAt: z.union([z.instanceof(Date), z.string()]).optional(),
})

export const licensePlan = z.object({
  id: z.number(),
  label: z.string(),
})

export const UPLOAD_STATUS = {
  WAITING: 'waiting',
  UPLOADED: 'uploaded',
  ANALYZING: 'analyzing',
  VALIDATING: 'validating',
  CONVERTING: 'converting',
  FAILED: 'failed',
  EXPIRED: 'expired',
} as const
export const uploadStatusType = z.enum([
  UPLOAD_STATUS.WAITING,
  UPLOAD_STATUS.UPLOADED,
  UPLOAD_STATUS.ANALYZING,
  UPLOAD_STATUS.VALIDATING,
  UPLOAD_STATUS.CONVERTING,
  UPLOAD_STATUS.FAILED,
  UPLOAD_STATUS.EXPIRED,
])
export type UploadStatusType = z.infer<typeof uploadStatusType>

export const BUNDLES_TYPE = {
  NONE: 'none',
  ITEM_BUNDLES: 'item_bundles',
  INCLUDE_ITEM: 'included_item',
} as const
export const bundlesType = z.enum([
  BUNDLES_TYPE.NONE,
  BUNDLES_TYPE.ITEM_BUNDLES,
  BUNDLES_TYPE.INCLUDE_ITEM,
])
export type BundlesType = z.infer<typeof bundlesType>

const _item = z.object({
  id: z.number(),
  user: user.optional(),
  licenseId: z.number().nullable(),
  userId: z.number().optional(),
  name: z.string(),
  nameEn: z.string().nullable(),
  creatorName: z.string().optional(),
  systemKey: z.string().optional(),
  license: z
    .object({
      id: z.number(),
      label: z.string(),
    })
    .optional(),
  assetId: z.number(),
  assetName: z.string(),
  category: categoryType,
  subCategory: subCategoryType,
  objectType: objectType.nullable(),
  licensePlans: z.array(licensePlan),
  thumbnailUrl: z.string().nullable(),
  subThumbnails: z.array(subThumbnail),
  youtubeUrl: z.string().nullable(),
  description: z.string(),
  descriptionEn: z.string().nullable(),
  termsOfUse: z.string().nullable(),
  termsOfUseEn: z.string().nullable(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]),
  version: z.string(),
  accessibleStatus: accessibleStatusType,
  publishedAt: z.union([z.instanceof(Date), z.string()]).nullable(),
  price: z.number().nullable(),
  fee: z.number(),
  discountRate: z.number().nullable(),
  numberOfLikes: z.number(),
  secondaryUse: z.boolean(),
  commercialUse: z.boolean(),
  myVketStoreUse: z.boolean(),
  isPickup: z.boolean(),
  isPaid: z.boolean(),
  isOfficial: z.boolean(),
  canPurchase: z.boolean(),
  purchaseCount: z.number(),
  downloadUrl: z.string().nullable(),
  sendSellNotification: z.boolean(),
  uploadStatus: uploadStatusType,
  hasAssetThumbnail: z.boolean(),
  bundlesType: bundlesType.optional(),
  isForcedPrivate: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
})
export const item = _item.extend({
  additionalItems: z.array(_item).optional(),
  bundledByItem: _item.optional(),
})
export type Item = z.infer<typeof item>

export const mypageItem = item
  .omit({
    licenseId: true,
    userId: true,
    creatorName: true,
    license: true,
    assetName: true,
    objectType: true,
    licensePlans: true,
    version: true,
    publishedAt: true,
    fee: true,
    myVketStoreUse: true,
    canPurchase: true,
    downloadUrl: true,
    sendSellNotification: true,
    hasAssetThumbnail: true,
    createdAt: true,
    updatedAt: true,
  })
  .extend({
    // NOTE: 仕様書と実際のデータが異なるため、userIdをnullableに設定
    userId: z.number().nullable(),
  })
export type MypageItem = z.infer<typeof mypageItem>

export const itemPurchased = z.object({
  item,
  purchased: z.boolean().optional(),
  liked: z.boolean().optional(),
})

export const itemPurchasedWithProfile = itemPurchased.extend({
  profile: profileData.nullable(),
})

export const itemBrowseLog = z.object({
  user,
  item,
})

// 決済
export const PAYMENT_TYPE = {
  STRIPE: 'stripe',
  PAYPAL: 'paypal',
  GMO_PAYMENT: 'gmo_payment',
} as const

export const paymentType = z.enum([
  PAYMENT_TYPE.STRIPE,
  PAYMENT_TYPE.PAYPAL,
  PAYMENT_TYPE.GMO_PAYMENT,
])

export type PaymentType = z.infer<typeof paymentType>

export const orderPaypal = z.object({
  paypalOrderId: z.string(),
  invoiceId: z.string(),
})

export const orderStripe = z.object({
  paymentMethodId: z.string(),
  paymentIntentId: z.string(),
})

export const orderGmoPayment = z.object({
  gmoPaymentOrderId: z.string().nullable(),
})

export const order = z.object({
  id: z.number(),
  user,
  uuid: z.string(),
  totalPrice: z.number(),
  paymentType,
  isPaymentCompleted: z.boolean(),
  closingDate: z.union([z.instanceof(Date), z.string()]),
  paypal: orderPaypal.nullable().optional(),
  stripe: orderStripe.nullable().optional(),
  gmoPayment: orderGmoPayment.nullable().optional(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
})

export const wallet = z.object({
  id: z.number(),
  userId: z.string(),
  paymentMethodId: z.string(),
  last4Digits: z.string(),
  brand: z.string(),
  isDefault: z.boolean(),
})

export const orderItem = z.object({
  id: z.number(),
  orderId: z.number(),
  itemId: z.number(),
  name: z.string(),
  nameEn: z.string().nullable(),
  assetId: z.number(),
  category: categoryType,
  thumbnailUrl: z.string().nullable(),
  youtubeUrl: z.string().nullable(),
  description: z.string(),
  descriptionEn: z.string().nullable(),
  termsOfUse: z.string().nullable(),
  termsOfUseEn: z.string().nullable(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]),
  accessibleStatus: accessibleStatusType,
  price: z.number().nullable(),
  fee: z.number().nullable(),
  discountRate: z.number().nullable(),
  numberOfLikes: z.number(),
  secondaryUse: z.boolean(),
  commercialUse: z.boolean(),
  isOfficial: z.boolean(),
  isPickup: z.boolean(),
  closingDate: z.string(),
  uuid: z.string(),
  user,
  item,
})

export const orderItemHistory = z.object({
  id: z.number(),
  orderId: z.number().nullable(),
  itemId: z.number(),
  name: z.string(),
  nameEn: z.string().nullable(),
  assetId: z.number(),
  category: categoryType,
  thumbnailUrl: z.string().nullable(),
  youtubeUrl: z.string().nullable(),
  description: z.string(),
  descriptionEn: z.string().nullable(),
  termsOfUse: z.string().nullable(),
  termsOfUseEn: z.string().nullable(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]),
  accessibleStatus: accessibleStatusType,
  price: z.number().nullable(),
  fee: z.number().nullable(),
  discountRate: z.number().nullable(),
  numberOfLikes: z.number(),
  secondaryUse: z.boolean(),
  commercialUse: z.boolean(),
  isOfficial: z.boolean(),
  isPickup: z.boolean(),
  closingDate: z.string(),
  uuid: z.string().nullable(),
  user,
  item,
})

export const orderFreeItemHistory = orderItemHistory
  .omit({ orderId: true, uuid: true })
  .extend({
    userId: z.number(),
  })

export const orderItemWithProfile = orderItemHistory.extend({
  profile: profileData.nullable(),
})

export const orderItemDetail = z.object({
  id: z.number(),
  uuid: z.string(),
  totalPrice: z.number(),
  paymentType,
  isPaymentCompleted: z.boolean(),
  closingDate: z.union([z.instanceof(Date), z.string()]),
  paypal: orderPaypal.nullable(),
  stripe: orderStripe.nullable(),
  itemsCount: z.number(),
  items: z.array(orderItemHistory),
})

export const orderItemDetailWithProfile = orderItemHistory.extend({
  profile: profileData.nullable(),
})

export const orderFreeItemHistoryDetail = z.object({
  id: z.number(),
  totalPrice: z.number(),
  closingDate: z.union([z.instanceof(Date), z.string()]),
  item: orderFreeItemHistory,
})

export const orderFreeItemDetailWithProfile = orderFreeItemHistory.extend({
  profile: profileDetails.nullable(),
})

export const forcedPrivateItem = z.object({
  item: itemPurchased.shape.item,
  reason: z.string(),
})

export type User = z.infer<typeof user>
export type CloudStoreItem = z.infer<typeof item>
export type ItemPurchased = z.infer<typeof itemPurchased>
export type ItemPurchasedWithProfile = z.infer<typeof itemPurchasedWithProfile>
export type ItemBrowseLog = z.infer<typeof itemBrowseLog>
export type Order = z.infer<typeof order>
export type Wallet = z.infer<typeof wallet>
export type OrderItem = z.infer<typeof orderItem>
export type OrderItemHistory = z.infer<typeof orderItemHistory>
export type OrderItemWithProfile = z.infer<typeof orderItemWithProfile>
export type OrderItemDetail = z.infer<typeof orderItemDetail>
export type OrderItemDetailWithProfile = z.infer<
  typeof orderItemDetailWithProfile
>
export type OrderFreeItemHistory = z.infer<typeof orderFreeItemHistory>
export type OrderFreeItemHistoryDetail = z.infer<
  typeof orderFreeItemHistoryDetail
>
export type OrderFreeItemDetailWithProfile = z.infer<
  typeof orderFreeItemDetailWithProfile
>
export type ForcedPrivateItem = z.infer<typeof forcedPrivateItem>

//* GET *//
// [販売者用] 自分の登録した販売商品一覧
export const getItemsRequest = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
})

export type GetItemsRequest = z.infer<typeof getItemsRequest>

export const getItemsResponse = z.object({
  itemCount: z.number(),
  items: z.array(item),
})

export type GetItemsResponse = z.infer<typeof getItemsResponse>

// [購入者用] 最近見た商品一覧取得
export const getItemsPublishedRecentRequest = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
})

export type GetItemsPublishedRecentRequest = z.infer<
  typeof getItemsPublishedRecentRequest
>

export const getItemsPublishedRecentResponse = z.object({
  itemCount: z.number(),
  items: z.array(item),
})

export type GetItemsPublishedRecentResponse = z.infer<
  typeof getItemsPublishedRecentResponse
>

//* POST *//
// [購入者用] 商品購入
export const postItemPurchaseRequest = z.object({
  id: z.number(),
})

export type PostItemPurchaseRequest = z.infer<typeof postItemPurchaseRequest>

export const postItemPurchaseResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})
export type PostItemPurchaseResponse = z.infer<typeof postItemPurchaseResponse>

//* PUT *//

//* PATCH *//

//* DELETE *//
// [購入者用] 商品にいいね取り消し
export const deleteItemLikeRequest = z.object({
  id: z.number(),
})

export type DeleteItemLikeRequest = z.infer<typeof deleteItemLikeRequest>

export const deleteItemLikeResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})

export type DeleteItemLikeResponse = z.infer<typeof deleteItemLikeResponse>

export const targetAge = z.enum([TARGET_AGE.allAges, TARGET_AGE.r18])
export type TargetAge = z.infer<typeof targetAge>
