<i18n lang="yaml">
ja:
  login: 新規登録/ログイン
  login-sp: 新規登録
  logout: ログアウト
  account: アカウント
  mypage: マイページ
  language: Language
  contact: お問い合わせ
  mycontents:
    title: コンテンツ
    notification: お知らせ
    guide: 遊び方
    help: ヘルプ
    talkroom: トークルーム
    room: ルーム
    world: ワールド
    event: イベント
    store: ストア
  myMenu:
    title: マイメニュー
    avatar: アバター/アイテム管理
    eventManagement: イベント管理
    footprint: 足あと
    follow: フォロー・フォロワー
    message: メッセージ
    block: ブロック管理
    like: いいね
    profile: プロフィール編集
    myroom: マイルーム管理
    achievement: メダル
    orderhistory: 購入履歴
    sales-management: 売上管理
  service:
    title: 関連サービス
    vketstore: Vket Store
    vketcloud: Vket cloud
    virtual: バーチャルマーケット
    avatarmaker: AVATAR MAKER
    boothmaker: Vket Booth Maker
    threadtale: ThreadTale
  terms:
    title: 規約・法的表記
    twitter: 公式X（旧Twitter）
    terms: 利用規約
    privacy: プライバシーポリシー
    commerce: 特定商取引に基づく表記
    licence: ライセンス
    hikky: 株式会社HIKKY
  buttons:
    tutorial: チュートリアル
    setting: 設定
    account: アカウント
    login: ログイン
    logout: ログアウト
  pwa:
    addtext: My Vketをホームに追加しませんか？
    description: いつでもすぐにMy Vketを開くことができます。
    button: ホームアイコンを作る
en:
  login: Login/Sign Up
  login-sp: Sign Up
  logout: Log out
  account: Account
  mypage: MyPage
  language: Language
  contact: Contact
  mycontents:
    title: Contents
    notification: Notification
    guide: How to play
    help: Help
    talkroom: Talkroom
    room: Room
    world: World
    event: Event
    store: Store
  myMenu:
    title: My menu
    avatar: Avatar/Item Management
    eventManagement: Event Management
    footprint: Footprint
    follow: follow・follower
    message: Message
    block: Block
    like: Like
    profile: Profile
    myroom: Myroom
    achievement: Medals
    orderhistory: Order History
    sales-management: Sales and Profit
  service:
    title: Service
    vketstore: Vket Store
    vketcloud: Vket cloud
    virtual: Virtual market
    avatarmaker: AVATAR MAKER
    boothmaker: Vket Booth Maker
    threadtale: ThreadTale
  terms:
    title: Terms・Legal
    twitter: X (Twitter)
    terms: Terms of Use
    privacy: Privacy
    commerce: Commerce
    licence: Licence
    hikky: HIKKY
  buttons:
    tutorial: Tutorial
    setting: Setting
    account: Account
    login: Login
    logout: Logout
  pwa:
    addtext: Want to add My Vket to your home?
    description: You can open My Vket immediately at any time.
    button: Create a home icon
</i18n>
<template>
  <header class="ho-the-header">
    <nav class="header-container">
      <HoSideMenu
        class="side-menu"
        :class="{ '-slide-in': isOpenSideMenu, '-hide': isHideSideMenu }"
      />
      <HaLink to="/" class="header-logo">
        <LogoMyvket class="logo" />
      </HaLink>
      <HaLink :to="LINK.TOLASS_CHAN" blank>
        <HaBaseImage src="/images/icon-vanilla.png" class="header-symbol" />
      </HaLink>
      <ul class="header-menu">
        <ClientOnly>
          <li v-if="isPc" class="menu-item">
            <HaLink :to="LINK.HOW_TO_USE" class="link">
              <IconBeginner class="icon" />
              <template v-if="isPc">
                <span class="text">{{ i18n.t('mycontents.guide') }}</span>
              </template>
            </HaLink>
          </li>
          <li v-if="isPc" class="menu-item">
            <HaLink to="/help" class="link">
              <IconQuestion class="icon" />
              <template v-if="isPc">
                <span class="text">{{ i18n.t('mycontents.help') }}</span>
              </template>
            </HaLink>
          </li>
          <li v-if="isPc && isGottenMe" class="menu-item">
            <HaLink to="/mypage/notification" class="link">
              <IconNoticeBell class="icon" />
              <template v-if="isPc">
                <span class="text">{{
                  i18n.t('mycontents.notification')
                }}</span>
              </template>
            </HaLink>
          </li>
          <li class="menu-item">
            <template v-if="profile">
              <HaLink to="/mypage">
                <HoMyVketButton
                  v-if="isPc"
                  :class="['profile-name']"
                  category="icon"
                  :src="profile.icon.url || '/images/no-image-512x512.webp'"
                  @focus="onClickButtonFocusout"
                >
                  <template v-if="!isSp"> {{ i18n.t('mypage') }} </template>
                </HoMyVketButton>
              </HaLink>
            </template>
            <template v-else-if="rootState.isLoading">
              <!-- ログイン確認中は非表示 -->
            </template>
            <template v-else>
              <HoMyVketButton class="login-button" @click="onLogin">
                <IconMyvket class="icon" />{{
                  isPc ? i18n.t('login') : i18n.t('login-sp')
                }}
              </HoMyVketButton>
            </template>
          </li>
        </ClientOnly>
      </ul>
      <div class="hamburger-button" @click="onClickMypage">
        <HaHamburger :is-open="isOpenMyMenu" />
      </div>
      <!-- マイページメニューの中身 -->
      <div
        ref="myMenu"
        class="expand-menu-wrapper"
        :class="{ '-fade-out': !isOpenMyMenu, '-hide': isHideMyMenu }"
      >
        <div class="expand-menu">
          <div class="navigation-wrapper">
            <ClientOnly>
              <template v-if="isPc">
                <template
                  v-for="(item, index) in navigationDataPc"
                  :key="index"
                >
                  <template v-if="item.isShow">
                    <div class="dropdown">
                      <div
                        class="dropdown-toggle"
                        @click="toggleDropdown(index)"
                      >
                        {{ item.title }}
                      </div>
                      <ul class="dropdown-options" :class="item.label">
                        <template v-for="(option, optionIndex) in item.options">
                          <li
                            v-if="option.isShow"
                            :key="optionIndex"
                            class="item"
                            :class="{ '-logged': isGottenMe }"
                          >
                            <HaLink
                              class="link"
                              :to="option.link"
                              :blank="option.blank"
                            >
                              <template v-if="option.icon === ''">
                                <span class="text">{{ option.name }}</span>
                                <IconExternal class="iconlink" />
                              </template>
                              <template v-else>
                                <component :is="option.icon" class="icon" />
                                <span class="text">{{ option.name }}</span>
                              </template>
                            </HaLink>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </template>
                </template>
              </template>
              <template v-else>
                <template
                  v-for="(item, index) in navigationDataSp"
                  :key="index"
                >
                  <template v-if="item.isShow">
                    <div v-if="item.title" class="dropdown">
                      <div
                        class="dropdown-toggle"
                        @click="toggleDropdown(index)"
                      >
                        {{ item.title }}
                        <IconArrowBelow
                          class="icon"
                          :class="{ '-active': activeDropdown === index }"
                        />
                      </div>
                      <div
                        class="dropdown-inner"
                        :class="{ '-active': activeDropdown === index }"
                      >
                        <ul class="dropdown-options">
                          <li
                            v-for="(option, Index) in item.options"
                            :key="Index"
                            class="item"
                          >
                            <HaLink
                              class="link"
                              :to="option.link"
                              :blank="option.blank"
                            >
                              <template v-if="option.icon === ''">
                                <span class="text">{{ option.name }}</span>
                                <IconExternal class="iconlink" />
                              </template>
                              <template v-else>
                                <component :is="option.icon" class="icon" />
                                <span class="text">{{ option.name }}</span>
                              </template>
                            </HaLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div v-else class="dropdown">
                      <ul class="dropdown-options">
                        <template v-for="(option, optionIndex) in item.options">
                          <li
                            v-if="option.isShow"
                            :key="optionIndex"
                            class="item"
                          >
                            <HaLink
                              class="link"
                              :to="option.link"
                              :blank="option.blank"
                            >
                              <component :is="option.icon" class="icon" />
                              <span class="text">{{ option.name }}</span>
                            </HaLink>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </template>
                </template>
              </template>
              <template v-if="!isPc">
                <HaLink :to="LINK.CONTACT">
                  <div class="dropdown-toggle">{{ i18n.t('contact') }}</div>
                </HaLink>
              </template>
            </ClientOnly>
          </div>
          <div class="sidenav-wrapper">
            <div class="language-wrapper">
              <div class="text">{{ i18n.t('language') }}</div>
              <div
                class="detail"
                :class="{ '-active': i18n.locale.value === 'ja' }"
                @click="onChangeLocale('ja')"
              >
                日本語
              </div>
              <div>/</div>
              <div
                class="detail"
                :class="{ '-active': i18n.locale.value === 'en' }"
                @click="onChangeLocale('en')"
              >
                English
              </div>
            </div>
            <div class="buttons-list" :class="{ '-logged': isGottenMe }">
              <ClientOnly>
                <template v-if="isPc">
                  <HaLink :to="`/tutorial`" redirect class="link">
                    <HoMyVketButton category="gray" outline class="button">
                      {{ i18n.t('buttons.tutorial') }}
                    </HoMyVketButton>
                  </HaLink>
                  <HaLink
                    v-if="isGottenMe"
                    :to="`/mypage/setting`"
                    class="link"
                  >
                    <HoMyVketButton category="gray" outline class="button">
                      {{ i18n.t('buttons.setting') }}
                    </HoMyVketButton>
                  </HaLink>
                  <HaLink :to="LINK.CONTACT" class="link">
                    <HoMyVketButton category="gray" outline class="button">
                      {{ i18n.t('contact') }}
                    </HoMyVketButton>
                  </HaLink>
                  <HaLink
                    v-if="isGottenMe"
                    :to="`${LINK.ACCOUNT}/mypage/user`"
                    blank
                    class="link"
                  >
                    <HoMyVketButton category="gray" outline class="button">
                      {{ i18n.t('buttons.account') }}
                    </HoMyVketButton>
                  </HaLink>
                  <HoMyVketButton
                    v-if="isGottenMe"
                    category="gray"
                    outline
                    class="button"
                    @click="onLogout"
                  >
                    {{ i18n.t('buttons.logout') }}
                  </HoMyVketButton>
                  <HoMyVketButton
                    v-if="!isGottenMe"
                    category="gray"
                    outline
                    class="button"
                    @click="onLogin"
                  >
                    {{ i18n.t('buttons.login') }}
                  </HoMyVketButton>
                </template>
                <template v-else>
                  <HaLink :to="`/tutorial`" redirect class="link">
                    <HoMyVketButton category="gray" outline class="button">
                      {{ i18n.t('buttons.tutorial') }}
                    </HoMyVketButton>
                  </HaLink>
                  <HaLink
                    v-if="isGottenMe"
                    :to="`/mypage/setting`"
                    class="link"
                  >
                    <HoMyVketButton category="gray" outline class="button">
                      {{ i18n.t('buttons.setting') }}
                    </HoMyVketButton>
                  </HaLink>
                  <HaLink
                    v-if="isGottenMe"
                    :to="`${LINK.ACCOUNT}/mypage/user`"
                    blank
                    class="link"
                  >
                    <HoMyVketButton category="gray" outline class="button">
                      {{ i18n.t('buttons.account') }}
                    </HoMyVketButton>
                  </HaLink>
                  <HoMyVketButton
                    v-if="isGottenMe"
                    category="gray"
                    outline
                    class="button"
                    @click="onLogout"
                  >
                    {{ i18n.t('buttons.logout') }}
                  </HoMyVketButton>
                  <HoMyVketButton
                    v-if="!isGottenMe"
                    outline
                    class="button"
                    @click="onLogin"
                  >
                    {{ i18n.t('login') }}
                  </HoMyVketButton>
                </template>
              </ClientOnly>
            </div>
            <div class="copyright">&copy;HIKKY All rights reserved.</div>
          </div>
        </div>
        <div v-if="isShowPwaContent" class="pwa-content">
          <div class="text-wrapper">
            <b class="bold">{{ i18n.t('pwa.addtext') }}</b>
            <p class="description">
              {{ i18n.t('pwa.description') }}
            </p>
          </div>
          <HoMyVketButton class="button" @click="installPwa">
            <IconMyvket class="icon" />
            <p class="text">{{ i18n.t('pwa.button') }}</p>
          </HoMyVketButton>
        </div>
      </div>
    </nav>
    <div
      v-if="isOpenMyMenu || isOpenSideMenu"
      class="overlay"
      :class="{ '-show': isOpenMyMenu || isOpenSideMenu }"
      @click.stop="hideMenu"
    />
  </header>
</template>
<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

// assets
import LogoMyvket from '@/assets/logo-myvket-beta.svg'
import IconNoticeBell from '@/assets/icons/icon_notice_bell.svg'
import IconBeginner from '@/assets/icons/icon_manuel.svg'
import IconManuel from '@/assets/icons/icon-manuel.svg'
import IconQuestion from '@/assets/icons/icon_help.svg'
import IconProfile from '@/assets/icons/icon_home.svg'
import Iconfollow from '@/assets/icons/icon_followers.svg'
import IconMessage from '@/assets/icons/icon_message.svg'
import IconBlock from '@/assets/icons/icon_block.svg'
import IconAchievement from '@/assets/icons/icon-achievement-medal.svg'
import IconNotification from '@/assets/icons/icon-notification.svg'
import IconLike from '@/assets/icons/icon_good_on.svg'
import IconExternal from '@/assets/icons/icon_arrow_up_right_from_square_solid.svg'
import IconVisitor from '@/assets/icons/icon_visitor_foot.svg'
import IconEvent from '@/assets/icons/icon_event.svg'
import IconShop from '@/assets/icons/icon_shop.svg'
import IconManagement from '@/assets/icons/icon_management.svg'
import IconRoom from '@/assets/icons/icon_room.svg'
import IconCloset from '@/assets/icons/icon_closet.svg'
import IconArrowBelow from '@/assets/icons/icon-arrow-below.svg'
import IconMyvket from '@/assets/icons/icon-myvket.svg'
import IconWorld from '@/assets/icons/icon_world.svg'
import IconPurchaseHisotry from '@/assets/icons/icon_purchase_history.svg'

// composables
import { authInjectionKey } from '@/composables/useAuth'
import { Lang } from '@/composables/useLocale'
import { breakpoints } from '@/composables/useBreakpoints'

const route = useRoute()

const isPc = breakpoints.greaterOrEqual('pc')
const isTb = breakpoints.smaller('pc')
const isSp = breakpoints.smaller('sp')

const root = useRoot()
provide(rootInjectionKey, root)

const i18n = useI18n()
const locale = useLocale()
const toast = useToast()
const { addToast } = toast
const { state } =
  inject(myProfileInjectionKey) ?? raiseError('myProfileInjectionKey')
const { state: rootState } =
  inject(rootInjectionKey) ?? raiseError('rootInjectionKey')
const { state: notificationState } =
  inject(notificationInjectionKey) ?? raiseError('notificationInjectionKey')
const isJa = computed(() => i18n.locale.value === 'ja')

const { me, login, logout, isGottenMe } =
  inject(authInjectionKey) ?? raiseError('authInjectionKey')

const {
  myMenu,
  myMenuHeight,
  bodyContent,
  isOpenSideMenu,
  isHideSideMenu,
  isOpenMyMenu,
  isHideMyMenu,
  onClickMypage,
  hideMenu,
} = inject(mypageMenuInjectionKey) ?? raiseError('mypageMenuInjectionKey')

const profile = computed(() => state.value.profile)

const followUnreadCount = computed(() =>
  notificationState.summary?.followUnreadCount
    ? notificationState.summary.followUnreadCount > 10
      ? '9+'
      : notificationState.summary.followUnreadCount
    : 0
)
const messageUnreadCount = computed(() =>
  notificationState.summary?.messageUnreadCount
    ? notificationState.summary.messageUnreadCount > 10
      ? '9+'
      : notificationState.summary.messageUnreadCount
    : 0
)

const contentsMenus = computed(() => [
  {
    title: '',
    label: '-content',
    isShow: true,
    options: [
      {
        name: i18n.t('mycontents.notification'),
        icon: IconNoticeBell,
        link: '/mypage/notification',
        blank: false,
        isShow: isGottenMe.value,
      },
      {
        name: i18n.t('mycontents.guide'),
        icon: IconManuel,
        link: '/guide',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('mycontents.help'),
        icon: IconQuestion,
        link: '/help',
        blank: false,
        isShow: true,
      },
    ],
  },
])

const navigationData = computed(() => [
  {
    title: i18n.t('mycontents.title'),
    label: '-mycontent',
    isShow: true,
    options: [
      {
        name: i18n.t('mycontents.talkroom'),
        icon: IconProfile,
        link: '/talkroom',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('mycontents.room'),
        icon: IconRoom,
        link: '/myroom/list',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('mycontents.event'),
        icon: IconEvent,
        link: '/play/event',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('mycontents.world'),
        icon: IconWorld,
        link: '/play/world',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('mycontents.store'),
        icon: IconShop,
        link: '/cloudstore',
        blank: false,
        isShow: true,
      },
    ],
  },
  {
    title: i18n.t('myMenu.title'),
    label: '-mymenu',
    isShow: isGottenMe.value,
    options: [
      {
        name: i18n.t('myMenu.avatar'),
        icon: IconCloset,
        link: '/mypage/assets/avatar',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.eventManagement'),
        icon: IconEvent,
        link: '/mypage/event/participating',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.footprint'),
        icon: IconVisitor,
        link: '/mypage/notification/footprints',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.follow'),
        icon: Iconfollow,
        link: followUnreadCount ? '/mypage/follower' : '/mypage/follow',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.message'),
        icon: IconMessage,
        link: '/mypage/message',
        blank: false,
        isShow: isGottenMe.value,
      },
      {
        name: i18n.t('myMenu.block'),
        icon: IconBlock,
        link: '/mypage/block',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.like'),
        icon: IconLike,
        link: '/mypage/like/store',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.profile'),
        icon: IconProfile,
        link: '/mypage/profile',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.myroom'),
        icon: IconRoom,
        link: '/mypage/myroom',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.achievement'),
        icon: IconAchievement,
        link: '/mypage/achievement?sort=new',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.orderhistory'),
        icon: IconPurchaseHisotry,
        link: '/mypage/orderhistory',
        blank: false,
        isShow: true,
      },
      {
        name: i18n.t('myMenu.sales-management'),
        icon: IconManagement,
        link: '/mypage/sales/list',
        blank: false,
        isShow: true,
      },
    ],
  },
  {
    title: i18n.t('service.title'),
    label: '-service',
    isShow: true,
    options: [
      {
        name: i18n.t('service.vketstore'),
        icon: '',
        link: LINK.VKET_STORE,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('service.vketcloud'),
        icon: '',
        link: isJa.value ? LINK.CLOUD.JP : LINK.CLOUD.EN,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('service.virtual'),
        icon: '',
        link: isJa.value ? LINK.VIRTUAL_MARKET.JP : LINK.VIRTUAL_MARKET.EN,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('service.avatarmaker'),
        icon: '',
        link: LINK.AVATAR_MAKER_LP,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('service.boothmaker'),
        icon: '',
        link: isJa.value ? LINK.BOOTH_MAKER.JP : LINK.BOOTH_MAKER.EN,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('service.threadtale'),
        icon: '',
        link: LINK.THREAD_TALE,
        blank: true,
        isShow: true,
      },
    ],
  },
  {
    title: i18n.t('terms.title'),
    label: '-terms',
    isShow: true,
    options: [
      {
        name: i18n.t('terms.twitter'),
        icon: '',
        link: LINK.TWITTER,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('terms.terms'),
        icon: '',
        link: isJa.value ? LINK.TERMS.JP : LINK.TERMS.EN,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('terms.privacy'),
        icon: '',
        link: LINK.POLICY,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('terms.commerce'),
        icon: '',
        link: LINK.COMMERCE,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('terms.licence'),
        icon: '',
        link: LINK.LICENSE,
        blank: true,
        isShow: true,
      },
      {
        name: i18n.t('terms.hikky'),
        icon: '',
        link: LINK.COMPANY,
        blank: true,
        isShow: true,
      },
    ],
  },
])

// SP用 navigation
const navigationDataSp = computed(() => [
  ...contentsMenus.value,
  ...navigationData.value,
])
// PC用 navigation
const navigationDataPc = computed(() => {
  return navigationData.value.map((menu, index) => {
    if (index === 0 && contentsMenus.value[0]) {
      const merged = { ...menu }

      merged.options = [...contentsMenus.value[0].options, ...menu.options]
      return merged
    }
    return menu
  })
})

const onLogin = async () => {
  hideMenu()
  const result = await login()
  if (result) {
    addToast(i18n.t(result), 'error')
  }
}
const onLogout = () => {
  hideMenu()
  logout()
}

const onChangeLocale = async (lang: Lang) => {
  await locale.changeLocale(lang)
  // Note: ルートpathの場合のみ、ページ再描画処理が走らないのでリロードする
  if (route.path === '/') {
    location.href = '/'
  }
  if (route.path === '/en/' || route.path === '/en') {
    location.href = '/en'
  }
}

const activeDropdown = ref(-1)
// 言語変更
const isActiveDropdown = ref(false)
const dropdown = ref(null)
const openDropdown = () => {
  isActiveDropdown.value = true
}
onClickOutside(dropdown, (_) => {
  isActiveDropdown.value = false
})
// NOTE: マイページボタンがbuttonの為、focusが残りstyleが当たったままになるためfocusを強制的に外す。style側でfocusのstyleを外すだけでは、focus自体が解除されていない為にhoverのstyleが当たらず、どこかをクリックするまでfocusが残るので強制的に外す
const onClickButtonFocusout = (event: {
  currentTarget: { blur: () => void }
}) => {
  event.currentTarget.blur()
}

// PWAインストール
const isShowPwaContent = ref(false)
const installPwa = async () => {
  if (window.pwaInstall) {
    window.pwaInstall.prompt()
    await window.pwaInstall.userChoice
    window.pwaInstall = undefined
  }
}

watch(
  () => route.fullPath,
  () => {
    hideMenu()
  }
)

watch(isOpenMyMenu, () => {
  if (isOpenMyMenu.value) {
    myMenuHeight.value = myMenu.value?.clientHeight || 0
  } else {
    myMenuHeight.value = 0
  }
})

const toggleDropdown = (index: number) => {
  activeDropdown.value = activeDropdown.value === index ? -1 : index
}

onMounted(() => {
  bodyContent.value = document.getElementsByTagName('body')[0] || null

  // PWAのインストールを促すイベントを登録する
  window.addEventListener('beforeinstallprompt', (e) => {
    if ('prompt' in e) {
      e.preventDefault()
      window.pwaInstall = e as BeforeInstallPromptEvent
      isShowPwaContent.value = true
    } else {
      console.error('beforeinstallprompt is not supported')
    }
  })
})
</script>
<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use 'sass:color';

.header-logo {
  align-items: flex-start;
  color: v.$white;
  display: flex;
  gap: 5px;
  margin-left: v.space(4);

  @include m.sp {
    margin-left: 0;
  }

  > .logo {
    :deep(path) {
      fill: v.$text-body !important;
    }

    @include m.sp {
      width: 100px;
    }
  }
}

.header-symbol {
  height: 50px;
  margin-left: v.space(2);
  width: 50px;

  @include m.sp {
    height: 30px;
    margin-left: v.space(1);
    width: 30px;
  }
}

.header-container {
  align-items: center;
  background: v.$white;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 0;

  @include m.sp {
    padding: 0 10px;
  }

  > .side-menu {
    left: -120px;
    position: absolute;
    transition: left 0.2s ease-out;
    width: v.$side-menu-width;

    &.-slide-in {
      left: 0;
    }

    &.-hide {
      display: none;
    }
  }
}

.header-menu {
  align-items: center;
  display: flex;
  gap: v.space(6);
  justify-content: space-between;
  margin-left: auto;

  @include m.tb {
    gap: v.space(1);
  }
}

.menu-item {
  cursor: pointer;
  display: flex;
  transition: opacity 0.2s ease;

  > .link {
    align-items: center;
    display: flex;
    width: 100%;

    @include m.tb {
      padding: 0 v.space(1);
    }

    .text {
      white-space: nowrap;
    }

    .count {
      align-self: center;
      background: v.$red;
      border-radius: 6px;
      color: v.$white;
      display: block;
      font-size: 12px;
      margin-left: auto;
      min-width: 10px;
      padding: v.space(0.5) v.space(0.8);
      text-align: center;
    }
  }

  > .link:hover {
    .text {
      color: v.$orange;
    }
  }

  .icon {
    fill: v.$text-body;
    height: 16px;
    margin-right: v.space(1);
    width: 16px;

    @include m.tb {
      height: 20px;
      margin-right: v.space(0);
      width: 20px;
    }

    @include m.sp {
      height: 20px;
      margin-right: v.space(0);
      width: 20px;
    }

    :deep(path) {
      fill: v.$text-body !important;
    }
  }

  &.dropdown {
    padding: v.space(3);
    position: relative;
    transition: all 0.3s;
    @include m.tb {
      padding: v.space(3) v.space(1);
    }

    > .text {
      color: v.$text-link;
    }

    .details {
      background: v.$white;
      left: 0;
      min-width: 110px;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 100%;
      transition: all 0.3s;
      width: 100%;

      > .detail {
        padding: v.space(2) v.space(6);
        position: relative;
        text-align: center;

        &.-active {
          color: v.$orange;
        }
      }
    }

    @include m.pc {
      &:hover {
        background: v.$white;
        transition: all 0.3s;

        > .text {
          color: v.$text-body;
        }

        .details {
          opacity: 1;
          pointer-events: auto;
          transition: all 0.3s;
        }

        .detail {
          &:not(.-active):hover {
            color: v.$yellow;
          }
        }
      }
    }

    @include m.sp {
      > .text {
        display: none;
      }

      > .details {
        left: 50%;
        transform: translateX(-50%);
      }

      &.-active {
        > .text {
          color: v.$text-body;
        }

        .details {
          opacity: 1;
          pointer-events: auto;
          transition: all 0.3s;
        }

        .detail {
          &:not(.-active):hover {
            color: v.$yellow;
          }
        }
      }
    }
  }
}

.language-button {
  display: flex;
  justify-content: space-between;

  > .langlink {
    cursor: pointer;
    display: block;
    height: 20px;
    margin-right: 10px;
    opacity: 1;
    width: 20px;

    :deep(circle) {
      fill: v.$white !important;
      transition: fill 0.2s;
    }

    :deep(path) {
      fill: v.$white !important;
      transition: fill 0.2s;
    }

    &:hover {
      opacity: 1;

      :deep(circle) {
        fill: v.$white !important;
      }

      :deep(path) {
        fill: v.$white !important;
      }
    }

    &.-active {
      cursor: default;
      display: block;
      height: 20px;
      margin-right: 10px;
      pointer-events: none;
      width: 20px;

      :deep(circle) {
        fill: v.$white !important;
      }

      :deep(path) {
        fill: v.$white !important;
      }
    }
  }
}

.login-button[class] {
  align-items: center;
  color: v.$white;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;

  > .icon {
    :deep(path) {
      fill: v.$white !important;
    }
  }

  @include m.sp {
    &.-sp {
      border-radius: 3px;
      display: block;
      font-size: 12px;
      line-height: 1.5;
      padding: v.space(1) v.space(2);
      white-space: pre-wrap;
    }
  }
}

:deep(.profile-name[class]) {
  color: v.$white;
  height: 40px;

  .icon-wrapper {
    margin: 0;
  }

  @include m.sp {
    &.ho-my-vket-button {
      padding: 2px;

      .icon-wrapper {
        margin-right: 0;
        transform: translate(0);
      }
    }
  }
}

.hamburger-button {
  align-items: center;
  background-color: v.$gray;
  cursor: pointer;
  display: flex;
  height: 80px;
  justify-content: center;
  margin-left: v.space(5);
  width: 80px;

  :deep(.ha-humberger-button) {
    height: 26px;
    width: 36px;

    @include m.tb {
      height: 18px;
      width: 23px;
    }

    @include m.sp {
      height: 18px;
      width: 23px;
    }
  }

  @include m.tb {
    background-color: transparent;
    height: 53px;
    margin: 0;
    width: 53px;
  }

  @include m.sp {
    background-color: transparent;
    height: 53px;
    margin: 0;
    width: 53px;
  }
}

.expand-menu-wrapper {
  background-color: v.$white;
  border-radius: v.space(5);
  display: flex;
  flex-direction: column;
  gap: v.space(4);
  justify-content: space-between;
  left: v.space(8);
  margin: 0 auto;
  max-width: 1920px;
  opacity: 1;
  overflow: auto;
  padding: v.space(4);
  position: absolute;
  right: v.space(8);
  top: v.$header-height-pc + v.space(2);
  transition: opacity 0.2s ease, top, 0.2s ease, right 0.2s ease;
  z-index: -1;

  &.-fade-out {
    opacity: 0;
    top: 0;
  }

  &.-hide {
    display: none;
  }

  @include m.tb {
    border-radius: 0;
    flex-direction: column-reverse;
    gap: v.space(1);
    height: calc(100lvh - v.$header-height-sp);
    justify-content: flex-end;
    left: unset;
    margin: 0;
    padding: v.space(4) 0;
    right: 0;
    top: v.$header-height-sp;
    width: 305px;

    &.-fade-out {
      top: v.$header-height-sp;
    }
  }
}

.expand-menu {
  display: flex;
  justify-content: space-between;

  @include m.tb {
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: scroll;

    &.-fade-out {
      top: v.$header-height-sp;
    }
  }
}

.overlay {
  animation: fadein 0.3s ease;
  background-color: rgba(v.$text-body, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -10;
}

.dropdown {
  &:first-child {
    max-width: 410px;
  }

  @include m.tb {
    border-bottom: 1px solid v.$gray-1;
    padding: v.space(1) 0;
    position: relative;
  }

  @include m.sp {
    border-bottom: 1px solid v.$gray-1;
    padding: v.space(1) 0;
    position: relative;
  }
}

.dropdown-toggle {
  background-color: v.$gray;
  color: v.$text-body;
  font-weight: bold;
  line-height: 24px;
  padding: v.space(2);

  @include m.tb {
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    height: 36px;
    justify-content: space-between;
    padding: v.space(4);

    > .icon {
      animation: dropdown-rotation-down 0.3s ease-in-out forwards;

      :deep(path) {
        fill: v.$text-body !important;
      }

      &.-active {
        animation: dropdown-rotation-up 0.3s ease-in-out forwards;
      }
    }
  }

  @include m.sp {
    align-items: center;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    height: 36px;
    justify-content: space-between;
    padding: v.space(4);

    > .icon {
      animation: dropdown-rotation-down 0.3s ease-in-out forwards;

      :deep(path) {
        fill: v.$text-body !important;
      }

      &.-active {
        animation: dropdown-rotation-up 0.3s ease-in-out forwards;
      }
    }
  }
}

.dropdown-inner {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;

  &.-active {
    grid-template-rows: 1fr;
  }
}

.dropdown-options {
  display: flex;
  flex-direction: column;
  gap: v.space(4);
  overflow: hidden;
  padding: v.space(4) v.space(2);
  position: relative;

  @include m.tb {
    gap: 0;
    padding: 0 v.space(4);
  }

  @include m.sp {
    gap: 0;
    padding: 0 v.space(4);
  }

  &.-mycontent,
  &.-mymenu {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(
      2,
      minmax(calc((100% - 16px - 8px - 8px) / 2), 184px)
    );
  }

  &.-mycontent {
    > .item:nth-child(-n + 3) {
      grid-column: 1;
    }

    > .item:nth-child(n + 4) {
      grid-column: 2;
    }

    .-logged:nth-child(-n + 4) {
      grid-column: 1;
    }

    &.-logged:nth-child(n + 5) {
      grid-column: 2;
    }
  }

  &.-mymenu {
    > .item:nth-child(-n + 6) {
      grid-column: 1;
    }

    > .item:nth-child(n + 7) {
      grid-column: 2;
    }
  }

  > .item {
    @include m.tb {
      padding: v.space(2) 0;
    }

    @include m.sp {
      padding: v.space(2) 0;
    }

    .icon {
      fill: v.$text-body;
      height: 16px;
      margin-right: v.space(1);
      width: 16px;

      :deep(path) {
        fill: v.$text-body !important;
      }
    }

    .link {
      align-items: center;
      display: flex;

      @include m.tb {
        font-size: 14px;
        line-height: 20px;
      }

      @include m.sp {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .iconlink {
      fill: v.$gray-5;
      height: 12px;
      margin-left: v.space(1);
      width: 12px;
    }
  }
}

.navigation-wrapper {
  display: flex;
  gap: v.space(4);

  @include m.tb {
    flex-direction: column;
    gap: v.space(0);
  }

  @include m.sp {
    flex-direction: column;
    gap: v.space(0);
  }
}

.sidenav-wrapper {
  display: flex;
  flex-direction: column;
  gap: v.space(4);
  justify-content: space-between;
}

.language-wrapper {
  align-items: center;
  color: v.$gray-5;
  display: grid;
  font-size: 14px;
  gap: v.space(1);
  order: 2;

  @include m.tb {
    display: flex;
    justify-content: space-around;
    order: 2;
    padding: 0 v.space(4);
  }

  @include m.sp {
    display: flex;
    justify-content: space-around;
    order: 2;
    padding: 0 v.space(4);
  }

  > .text {
    grid-column: span 3;
    text-align: center;
  }

  > .detail {
    cursor: pointer;

    &.-active {
      color: v.$text-body;
      font-weight: 700;
    }
  }
}

.buttons-list {
  display: flex;
  flex-direction: column;
  gap: v.space(4);
  order: 1;

  @include m.tb {
    order: 2;
    padding: 0 v.space(4);
  }

  @include m.sp {
    order: 2;
    padding: 0 v.space(4);
  }

  &.-logged {
    @include m.tb {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 v.space(4);
    }
  }

  .button {
    font-size: 14px;
    height: 40px;
    padding: 0;
    width: 130px;
  }
}

.copyright {
  align-items: flex-end;
  color: v.$text-note;
  display: flex;
  flex: 1;
  font-size: 12px;
  font-weight: 16px;
  justify-content: center;
  order: 3;
  width: 100%;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dropdown-rotation-up {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes dropdown-rotation-down {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.pwa-content {
  align-items: center;
  align-self: stretch;
  background: v.$yellow-1;
  display: flex;
  justify-content: space-between;
  padding: v.space(4);

  @include m.tb {
    align-items: flex-start;
    background: v.$gray;
    display: flex;
    flex-direction: column;
    gap: v.space(2);
  }

  > .text-wrapper {
    align-items: center;
    display: flex;
    gap: v.space(4);
    padding-right: 0;

    @include m.tb {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: v.space(1);
    }
  }

  > .text-wrapper > .bold {
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;

    @include m.tb {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  > .text-wrapper > .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    @include m.tb {
      font-size: 12px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  > .button:not(.outline) {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    gap: v.space(1);
    height: 40px;
    justify-content: center;
    line-height: 24px;
    margin: 0;

    @include m.tb {
      height: 36px;
      width: 100%;
    }
  }

  > .button:not(.outline) > .icon {
    height: 20px;
    width: 20px;

    :deep(path) {
      fill: v.$white;
    }
  }

  > .button:not(.outline) > .text {
    color: #fff;

    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @include m.tb {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
</style>
