<template>
  <div class="ho-vrm-viewer">
    <template v-if="!isDisplay">
      <div
        class="loading-background"
        :style="{ backgroundImage: `url('${backgroundImage}')` }"
      />
    </template>
    <IconLoading v-if="!isDisplay || isLoading" class="loading" />
    <ClientOnly>
      <div class="canvas-wrapper">
        <template v-if="isControllable">
          <HoToggleControlButton
            class="control-button"
            :class="{ '-on': previewMode }"
            :control="previewMode"
            @click="togglePreview"
          />
        </template>
        <template v-if="isVrm && previewMode">
          <!-- エモート選択UI -->
          <div class="emote" :class="{ '-safari': styleSafari }">
            <!-- src/public/data/Scene/vrmviewerForDrop.jsonのmotionsのKeyを指定します -->
            <HoMypageEmoteRadio
              class="emote-options"
              name="emote-options"
              :options="emoteOptions"
              @change="changeOption"
            />
            <template v-if="optionType === 'emote'">
              <div class="emote-buttons">
                <template v-if="emoteType === 'default'">
                  <div
                    class="button -ryotefuri"
                    @click="playAnimation(EMOTE_TYPE.RYOTEFURI)"
                  />
                  <div
                    class="button -thumbsup"
                    @click="playAnimation(EMOTE_TYPE.THUMBSUP)"
                  />
                  <div
                    class="button -wavehand"
                    @click="playAnimation(EMOTE_TYPE.WAVEHAND)"
                  />
                  <div
                    class="button -doublepeace"
                    @click="playAnimation(EMOTE_TYPE.DOUBLEPEACE)"
                  />
                </template>
                <template v-if="emoteType === 'vketbox'">
                  <div
                    class="button -standing"
                    :class="{ '-active': activeEmote === 0 }"
                    @click="playAnimation(EMOTE_TYPE.STANDING)"
                  />
                  <div
                    class="button -standing5"
                    :class="{ '-active': activeEmote === 1 }"
                    @click="playAnimation(EMOTE_TYPE.STANDING_5)"
                  />
                  <div
                    class="button -standing-take-photo"
                    :class="{ '-active': activeEmote === 2 }"
                    @click="playAnimation(EMOTE_TYPE.STANDING_TAKE_PHOTO)"
                  />
                  <div
                    class="button -standing-cute"
                    :class="{ '-active': activeEmote === 3 }"
                    @click="playAnimation(EMOTE_TYPE.STANDING_CUTE)"
                  />
                  <div
                    class="button -standing-cool"
                    :class="{ '-active': activeEmote === 4 }"
                    @click="playAnimation(EMOTE_TYPE.STANDING_COOL)"
                  />
                </template>
              </div>
            </template>
            <template v-if="optionType === 'face'">
              <div class="emote-buttons">
                <div
                  class="button -neutral"
                  :class="{ '-active': activeFacial === 4 }"
                  @click="playFacialMotion(FACIAL_TYPE.neutral)"
                />
                <div
                  class="button -joy"
                  :class="{ '-active': activeFacial === 0 }"
                  @click="playFacialMotion(FACIAL_TYPE.joy)"
                />
                <div
                  class="button -angry"
                  :class="{ '-active': activeFacial === 1 }"
                  @click="playFacialMotion(FACIAL_TYPE.angry)"
                />
                <div
                  class="button -sorrow"
                  :class="{ '-active': activeFacial === 2 }"
                  @click="playFacialMotion(FACIAL_TYPE.sorrow)"
                />
                <div
                  class="button -fun"
                  :class="{ '-active': activeFacial === 3 }"
                  @click="playFacialMotion(FACIAL_TYPE.fun)"
                />
              </div>
            </template>
          </div>
        </template>
        <HmVrmViewer
          ref="canvas"
          class="canvas"
          :class="{
            '-display': isDisplay && !isLoading,
            '-control-disabled': !enableControl,
          }"
          :default-camera-height-pc="settings?.cameraHeightPc"
          @drop="onDrop"
        />
      </div>
    </ClientOnly>
  </div>
</template>

<script lang="ts" setup>
// assets
import IconLoading from '@/assets/icons/icon-loading.svg'
// composables
import { breakpoints } from '@/composables/useBreakpoints'
import {
  useVrmViewer,
  ViewerSettings,
  EMOTE_TYPE,
  FACIAL_TYPE,
} from '@/composables/useVrmViewer'
import { ValueOf } from '@/utils/types/types'
import {
  formatVketBoxEmoteType,
  formatVketBoxFacialType,
  formatVketBoxEmoteTypeId,
  formatVketBoxFacialTypeId,
} from '@/models/vketbox'

type Props = {
  uuid?: string
  vrmFile?: string | File
  fieldName?: string
  enableIdleMotion?: boolean
  drawingFullbody?: boolean
  loading?: boolean
  enableControl?: boolean // VRM画面操作できるかどうか
  previewMode: boolean // プレビューモードかどうか
  settings?: ViewerSettings
  isControllable?: boolean // コントロールボタン表示するかどうか
  emoteType?: 'default' | 'vketbox'
  emote?: number
  facial?: number
}

const canvas = ref()
const scriptLoadingTimer = ref<ReturnType<typeof setInterval> | null>(null)
const changeEmote = ref<number | undefined>(undefined)
const changeFacial = ref<number | undefined>(undefined)
const activeEmoteTab = ref<boolean>(true)

const isPc = breakpoints.greater('pc')
const isTb = breakpoints.smaller('pc')
const isSp = breakpoints.smaller('sp')
const vrmViewer = useVrmViewer()
const isLoading = computed(() => vrmViewer.isLoading.value || props.loading)

const props = withDefaults(defineProps<Props>(), {
  uuid: 'hoge',
  vrmFile: 'Avatar/Avatar.vrm',
  fieldName: 'Field/VRMViewerField/VRMViewerField.heo',
  enableIdleMotion: false,
  drawingFullbody: false,
  loading: false,
  enableControl: false,
  previewMode: false,
  settings: undefined,
  isControllable: false,
  emoteType: 'default',
  emote: formatVketBoxEmoteTypeId(EMOTE_TYPE.IDLE),
  facial: formatVketBoxFacialTypeId(FACIAL_TYPE.neutral),
})
const emit = defineEmits<{
  (e: 'drop', files: FileList): void
  (e: 'toggle-control', enable: boolean): void
  (e: 'change-emote', emoteType: ValueOf<typeof EMOTE_TYPE>): void
  (e: 'change-facial', facialType: ValueOf<typeof FACIAL_TYPE>): void
}>()

/** 背景ロード済み */
const backgroundImage = computed(() =>
  isPc.value
    ? props.settings?.backgroundImagePc || '/images/vrm-viewer-bg-natural.webp'
    : props.settings?.backgroundImageSp || '/images/vrm-viewer-bg-natural.webp'
)
const isDisplay = ref(false)
const optionType = ref('emote')
const styleSafari = ref(false)

const vrmFileName = computed(() =>
  typeof props.vrmFile === 'string' ? props.vrmFile : props.vrmFile.name
)
const isVrm = computed(() =>
  // vrmとhrmファイルのみ許可, クエリパラメータを除去
  /^.*[.vrm|.hrm]$/.test(vrmFileName.value.split('?')[0] || '')
)

const emoteOptions = computed(() => [
  {
    value: 'emote',
    label: props.emoteType === 'vketbox' ? 'POSE' : 'EMOTE',
    checked: activeEmoteTab.value,
  },
  // Note: faceはビューアーが対応したら実装
  {
    value: 'face',
    label: 'FACE',
    checked: !activeEmoteTab.value,
  },
])

const activeEmote = computed(() => {
  if (changeEmote.value !== undefined) return changeEmote.value
  return props.emote
})

const activeFacial = computed(() => {
  if (changeFacial.value !== undefined) return changeFacial.value
  return props.facial
})

const changeOption = (option: string) => {
  optionType.value = option
  if (option === 'face') {
    activeEmoteTab.value = false
    vrmViewer.focusFace()
  } else {
    activeEmoteTab.value = true
  }
}

const loadVRM = () => {
  if (!props.vrmFile) return
  vrmViewer.loadVRM(
    props.uuid,
    vrmFileName.value,
    props.enableIdleMotion,
    props.drawingFullbody
  )
}

const animationReset = ref()
const playAnimation = (animationType: ValueOf<typeof EMOTE_TYPE>) => {
  // 連続でアニメーションを再生する場合、前のIdleリセットをキャンセルする
  if (animationReset.value) {
    clearTimeout(animationReset.value)
  }

  changeEmote.value = formatVketBoxEmoteTypeId(animationType)
  emit('change-emote', animationType)
  vrmViewer.playAnimation(animationType)

  // エモートアニメーションが終わったらIDLEアニメーションに戻す
  // NOTE: vketboxのエモートはポーズなのでIDLEに戻さない
  if (props.emoteType === 'vketbox') {
    return
  }
  let animationTime = 6500
  switch (animationType) {
    case EMOTE_TYPE.RYOTEFURI:
      animationTime = 3200
      break
    case EMOTE_TYPE.THUMBSUP:
      animationTime = 5000
      break
    case EMOTE_TYPE.WAVEHAND:
      animationTime = 6500
      break
    case EMOTE_TYPE.DOUBLEPEACE:
      animationTime = 5500
      break
  }
  animationReset.value = setTimeout(
    () => {
      vrmViewer.playAnimation(EMOTE_TYPE.IDLE)
      clearTimeout(animationReset.value)
    },
    animationType === EMOTE_TYPE.IDLE ? 0 : animationTime
  )
}

const playFacialMotion = (animationType: ValueOf<typeof FACIAL_TYPE>) => {
  changeFacial.value = formatVketBoxFacialTypeId(animationType)
  emit('change-facial', animationType)
  vrmViewer.playFacialMotion(animationType)
}

const loadField = () => {
  vrmViewer.loadField(props.fieldName)
}

const loadBackGround = () => {
  vrmViewer.loadBackGround(backgroundImage.value)
}

const onDrop = (files: FileList) => {
  emit('drop', files)
}

const togglePreview = () => {
  emit('toggle-control', !props.previewMode)
}

const checkLoading = () => {
  if (vrmViewer.state.isLoaded) {
    loadBackGround()
    loadVRM()
    vrmViewer.checkLoading()
    clearInterval(scriptLoadingTimer.value!)
  }
}

watch([isPc, isTb, isSp], () => {
  loadBackGround()
})

watch([vrmViewer.isLoading], () => {
  if (vrmViewer.isLoading.value) {
    isDisplay.value = false
    return
  }

  const interval = setInterval(() => {
    if (vrmViewer.isLoading.value) return

    setTimeout(() => {
      const emoteId = formatVketBoxEmoteType(props.emote)
      if (emoteId) {
        vrmViewer.playAnimation(emoteId)
      }

      const facialId = formatVketBoxFacialType(props.facial)
      if (facialId) {
        vrmViewer.playFacialMotion(facialId)
      }

      isDisplay.value = true
    }, 1000)
    clearInterval(interval)
  }, 300)
})

watch(
  () => props.previewMode,
  () => {
    if (!isPc.value) {
      // HmVrmViewerのリサイズメソッドを呼び出す
      canvas?.value.resizeTransition()
    }
  }
)

onMounted(() => {
  styleSafari.value = isSafari() || false

  scriptLoadingTimer.value = setInterval(checkLoading, 100)
})
</script>

<style lang="scss">
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

@mixin image($url) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-size: contain;
}

.ho-vrm-viewer {
  position: relative;
  z-index: 0;

  > .loading-background {
    background-position: center;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
  }
}

.loading {
  bottom: 0;
  height: 100px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;

  @include m.tb() {
    bottom: auto;
    top: 200px;
  }
}

.canvas-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: -1;

  > .emote {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 1;

    @include m.tb {
      bottom: v.$sp-toolbar-height;

      &.-safari {
        bottom: v.$sp-toolbar-height-pwa-safari;
      }
    }
  }

  .emote-buttons {
    background-color: v.$gray-3;
    border-radius: 4px;
    display: flex;
    gap: 10px;
    padding: v.space(3);

    > .button {
      background-color: v.$gray-6;
      border-radius: 50%;
      height: 60px;
      width: 60px;

      &:hover {
        background-color: rgba(v.$white, 0.8);

        &::before {
          border: 1px solid v.$gray;
          border-radius: 50%;
          box-sizing: border-box;
          content: '';
          height: calc(100% + 4px);
          left: -2px;
          position: absolute;
          top: -2px;
          width: calc(100% + 4px);
        }
      }

      &.-active {
        border: solid 2px v.$primary-color;
      }

      @include m.sp {
        height: 45px;
        width: 45px;
      }

      // エモート
      &.-wavehand {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_item_wave.png');
      }

      &.-ryotefuri {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_item_ryotefuri.png');
      }

      &.-thumbsup {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_item_thumbsup.png');
      }

      &.-doublepeace {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_item_doublepeace.png');
      }

      &.-standing {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/pose_1.png');
      }

      &.-standing5 {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/pose_2.png');
      }

      &.-standing-take-photo {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/pose_3.png');
      }

      &.-standing-cute {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/pose_4.png');
      }

      &.-standing-cool {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/pose_5.png');
      }

      // 表情
      &.-neutral {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_face_neutral.png');
      }

      &.-joy {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_face_joy.png');
      }

      &.-angry {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_face_angry.png');
      }

      &.-sorrow {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_face_sorrow.png');
      }

      &.-fun {
        cursor: pointer;
        position: relative;
        @include image('/images/emote/action_face_fun.png');
      }
    }
  }

  > .control-button {
    height: 100px;
    position: absolute;
    right: v.space(35);
    top: v.space(20);
    width: 100px;
    z-index: 1;

    @include m.tb {
      right: v.space(5);
      top: v.space(8);
    }

    @include m.sp {
      height: 60px;
      width: 60px;
    }
  }

  > .canvas {
    height: 100%;
    opacity: 0;
    width: 100%;

    &.-display {
      opacity: 1;
    }

    &.-control-disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}
</style>
