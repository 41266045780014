import { z } from 'zod'
import { PaymentMethod } from '@stripe/stripe-js'
import { useCloudstoreApi } from '@/composables/repository/useCloudstoreApi'
import { order, wallet } from '@/models/cloudstore'

// post:/payment/gmo/payment_gmo:決済確定（GMOペイメント）
const postPaymentGmoRequest = z.object({
  uuid: z.string(),
  cardSeq: z.number(),
})
const postPaymentGmoResponse = z.object({
  order: z.object({
    id: z.number(),
    user: z.object({
      id: z.number(),
      vketId: z.string(),
      nameJa: z.string().nullable(),
      nameEn: z.string().nullable(),
      email: z.string().nullable(),
    }),
  }),
})
export type PostPaymentGmoRequest = z.infer<typeof postPaymentGmoRequest>
export type PostPaymentGmoResponse = z.infer<typeof postPaymentGmoResponse>

// post:/payment/gmo/orders:決済情報保存（GMOペイメント）
const postPaymentGmoOrdersRequest = z.object({
  items: z.array(
    z.object({
      itemId: z.number(),
      price: z.number(),
    })
  ),
})
const postPaymentGmoOrdersResponse = z.object({ uuid: z.string() })
export type PostPaymentGmoOrdersRequest = z.infer<
  typeof postPaymentGmoOrdersRequest
>
export type PostPaymentGmoOrdersResponse = z.infer<
  typeof postPaymentGmoOrdersResponse
>

// get:/payment/gmo/wallets:クレジットカード情報取得（GMOペイメント）
export const getPaymentGmoWalletsResponse = z.object({
  id: z.number(),
  userId: z.string(),
  cardSeq: z.number(),
  last4Digits: z.string(),
  brand: z.string(),
  isDefault: z.boolean(),
})
export type GetPaymentGmoWalletsResponse = z.infer<
  typeof getPaymentGmoWalletsResponse
>

// post:/payment/gmo/wallets:クレジットカード情報登録（GMOペイメント）
export const postPaymentGmoWalletsRequest = z.object({
  token: z.string(),
})
export const postPaymentGmoWalletsResponse = z.object({
  id: z.number(),
  userId: z.string(),
  cardSeq: z.number(),
  last4Digits: z.string(),
  brand: z.string(),
  isDefault: z.boolean(),
})
export type PostPaymentGmoWalletsRequest = z.infer<
  typeof postPaymentGmoWalletsRequest
>
export type PostPaymentGmoWalletsResponse = z.infer<
  typeof postPaymentGmoWalletsResponse
>

// post:/payment/gmo/3dsecure/pre:3D セキュア決済事前処理(SMBC GMO PAYMENT)
export const postPaymentGmo3dSecurePreRequest = z.object({
  uuid: z.string(),
  token: z.string(),
})
export const postPaymentGmo3dSecurePreResponse = z.object({
  redirectUrl: z.string(),
})
export type PostPaymentGmo3dSecurePreRequest = z.infer<
  typeof postPaymentGmo3dSecurePreRequest
>
export type PostPaymentGmo3dSecurePreResponse = z.infer<
  typeof postPaymentGmo3dSecurePreResponse
>

// post:/payment/gmo/3dsecure:3D セキュア決済確定処理(SMBC GMO PAYMENT)
export const postPaymentGmo3dSecureRequest = z.object({
  uuid: z.string(),
  accessId: z.string(),
})
export const postPaymentGmo3dSecureResponse = z.object({
  order,
})
export type PostPaymentGmo3dSecureRequest = z.infer<
  typeof postPaymentGmo3dSecureRequest
>
export type PostPaymentGmo3dSecureResponse = z.infer<
  typeof postPaymentGmo3dSecureResponse
>

const versionCode = 'v1'
const prefix = 'payment/gmo'

export const useGmoPaymentRepository = () => {
  const api = useCloudstoreApi()
  return {
    get: {
      async getPaymentGmoWallets(): Promise<GetPaymentGmoWalletsResponse> {
        const result = await api.get(`/${versionCode}/${prefix}/wallets`)
        return requireValueOf(getPaymentGmoWalletsResponse, result)
      },
    },
    post: {
      async postPaymentGmo(
        params: PostPaymentGmoRequest
      ): Promise<PostPaymentGmoResponse> {
        const result = await api.post(`/${versionCode}/${prefix}/payment_gmo`, {
          body: params,
        })
        return requireValueOf(postPaymentGmoResponse, result)
      },
      async postPaymentGmoOrders(
        params: PostPaymentGmoOrdersRequest
      ): Promise<PostPaymentGmoOrdersResponse> {
        const result = await api.post(`/${versionCode}/${prefix}/orders`, {
          body: params,
        })
        return requireValueOf(postPaymentGmoOrdersResponse, result)
      },
      async postPaymentGmoWallets(params: PostPaymentGmoWalletsRequest) {
        const result = await api.post(`/${versionCode}/${prefix}/wallets`, {
          body: params,
        })
        return requireValueOf(postPaymentGmoWalletsResponse, result)
      },
      async postPaymentGmo3dSecurePre(
        params: PostPaymentGmo3dSecurePreRequest
      ) {
        const result = await api.post(
          `/${versionCode}/${prefix}/3dsecure/pre`,
          {
            body: params,
          }
        )
        return requireValueOf(postPaymentGmo3dSecurePreResponse, result)
      },
      async postPaymentGmo3dSecure(params: PostPaymentGmo3dSecureRequest) {
        const result = await api.post(`/${versionCode}/${prefix}/3dsecure`, {
          body: params,
        })
        return requireValueOf(postPaymentGmo3dSecureResponse, result)
      },
    },
    put: {},
    patch: {},
    delete: {
      async deletePaymentGmoWallets(paymentMethodId: PaymentMethod['id']) {
        await api.delete(`/${versionCode}/${prefix}/wallets/${paymentMethodId}`)
      },
    },
  }
}
